.LogicValuesButton {
  width: 100%;
}

.LogicValuesButton-buttons {
  width: 100%;
}

.LogicValuesButton-buttons.static {
  cursor: not-allowed;
}

.LogicValuesButton-buttons > .button {
  min-width: 5em;
  width: 33%;
  padding: 4px 2px;
  transition: background-color 0.5s ease;

  min-height: 28pt;
}


.AllowedAnswers {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.AllowedAnswers > * {
  width: 33%;
  padding: 12px 12px 0 12px;
}

.AllowedAnswerButton {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}