@import "../../../jun-styling";

.IntersectionalBotPopup {
    position: fixed;
    bottom: 12px;
    animation: pop-up-from-bottom 1.5s ease-out;
    left: 10%;
    right: 10%;
    width: 80%;
    z-index: 1000;

    display: flex;
    flex-direction: row;
    gap: 0.5ex;
    align-items: flex-end;
}

@media (width <= 500px) {
    .IntersectionalBotPopup-Icon {
        display: none;
    }
}

.IntersectionalBotPopup > .Message-bot {
    margin-bottom: 30px;
}

.IntersectionalBotPopup-Buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}


@keyframes pop-up-from-bottom {
    0% {
        transform: translateY(200%);
    }

    100% {
        transform: translateY(0);
    }
}