@import "../../jun-styling";

.ViewResult {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
  grid-template-areas:
    "content sidebar";
  gap: 2em;
}

.ViewResult-sidebar {
  grid-area: sidebar;
}

.ViewResult-content {
  display: flex;
  flex-direction: column;
  gap: 1em;

  grid-area: content;
}

.ViewResult-content > .Result {
  margin-bottom: 48px;
}

.ViewResult-content > .Result > .card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2em;

  .title {
    width: 100%;
  }
}

.ViewResult-content > .Result.true > .card-header {
  background-color: $junGreen;
}

.ViewResult-content > .Result.false > .card-header {
  background-color: $junLightRed;
}

.ViewResult-content > .horizontal-separator {
  margin-top: 4ex;
}