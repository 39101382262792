@import "../../../jun-styling";

.ProfileMenu {
  border: 1px $junLightGray solid;
  border-radius: 5px;

  padding: 12px 6px;

  display: grid;
  grid-template-columns: auto min-content;
  grid-gap: 12px;
}