@import "../../jun-styling";

.Feedback {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Feedback > .btn {
  align-self: center;
  min-width: 70%;
}

.Feedback-button-group {
  display: flex;
  flex-direction: row;
  gap: 1ex;
  justify-content: center;
}

.Feedback-button-group > button {
  border: none;
  padding: 0;
  background: none;

  font-size: 32px;
}

button.rating-4 {
  color: $junGreen;
}
button.rating-3 {
  color: $junLightGreen;
}
button.rating-2 {
  color: $junYellow;
}
button.rating-1 {
  color: $junLightRed;
}
button.rating-0 {
  color: $junRed;
}