@media (min-width: 500px) {
  .LoadingTweet {
    width: 500px;
  }
}

@media (max-width: 500px) {
  .LoadingTweet {
    width: 100%;
  }
}

.LoadingTweet {
  margin: auto;

  display: grid;
  grid-template-columns: [computer] 64px [animation] auto [twitter] 50px;
  grid-template-rows: [animation] auto [description] auto;
  column-gap: 0.5em;
}

////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////   Animation Row   ////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
.computer-icon {
  grid-column: computer;
  grid-row: animation;
  font-size: 64px;
}
.internet-icon {
  grid-column: twitter;
  grid-row: animation;
  font-size: 50px;
}

.connection-animation-container {
  grid-column: animation;
  grid-row: animation;
  width: 100%;
  height: 60px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.animation-line {
  width: 100%;
  display: flex;
}

.connection-animation-container > div > .arrows {
  width: 80px;
  font-size: 16px;
  position: relative;
}
.connection-animation-container > div > .arrows.right {
  animation: LoadingTweet-move-right 4s linear infinite;
}
.connection-animation-container > div > .arrows.left {
  left: 100%;
  animation: LoadingTweet-move-right 4s linear infinite reverse;
}


@keyframes LoadingTweet-move-right {
  0% {
    left: -100px;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}


////////////////////////////////////////////////////////////////////////////////////
////////////////////////////   Description Row   ///////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
.load-tweet-text {
  grid-column: computer / twitter;
  grid-row: description;
}
.load-tweet-text:after {
  content: " ";
  animation: loading-dots 3s infinite;
}
@keyframes loading-dots {
  25% {
      content: " .";
  }
  50% {
    content: " .."
  }
  75% {
    content: " ..."
  }
}

.twitter-name {
  width: 0;
  grid-column: twitter;
  grid-row: description;
  font-weight: bold;
}