@import "jun-styling";

.App {
  display: grid;
  grid-template-rows: min-content auto min-content;
  grid-template-areas:
          "header"
          "main"
          "footer";
  min-height: 100vh;

  background-color: $junGray;
}

.Header {
  grid-area: header;
  background-color: white;
}

.Main {
  grid-area: main;
  background-color: white;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.Main-progress {
  margin: auto;
  max-width: 1920px;
  padding: 1em 1em;
}

.Main-content {
  max-width: 1200px;
  margin: auto;
  padding: 1em 1em;
}

.Footer {
  grid-area: footer;
  color: white;
}

/////////////////////////////////////////////////////////////////////////////////
//////////////////////// Overwrite default styling //////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
h1 {
  font-size: x-large!important;
}

h2 {
  font-size: large!important;
}

/////////////////////////////////////////////////////////////////////////////////
//////////////////////// General CSS classes ////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

.clickable {
  cursor: pointer;
}

.hidden {
  visibility: hidden;
}

.horizontal-separator {
  margin: 0 auto;
  width: 95%;
  height: 1px;
  min-height: 1px;
  background-color: $junSeparatorColor;
}

.vertical-separator {
  width: 1px;
  background-color: $junSeparatorColor;
  margin: 6px 0;
}

.rotating {
  animation: rotate 1.2s linear infinite both;
}

.button-no-color {
  background-color: transparent !important;
  color: black !important;
  border-color: transparent !important;
  --bs-btn-hover-color: black !important;
  --bs-btn-hover-bg: transparent !important;
  --bs-btn-hover-border-color: transparent !important;
  --bs-btn-focus-shadow-rgb: 0, 0, 0 !important;
  --bs-btn-active-color: transparent !important;
  --bs-btn-active-bg: transparent !important;
  --bs-btn-active-border-color: transparent !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0) !important;
  --bs-btn-disabled-color: transparent !important;
  --bs-btn-disabled-bg: transparent !important;
  --bs-btn-disabled-border-color: transparent !important;
}

.inline-button {
  display: inline-flex !important;
  align-items: center;
  padding: 0 !important;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(365deg);
  }
}


a.no-color {
  color: unset !important;
}

a.no-underline {
  text-decoration: unset !important;
}


.result-background.true {
  background-color: $junGreen;
}

.result-background.false {
  background-color: $junLightRed;
}

.result-background.unknown {
  background-color: $junGray;
}

.result-background.nonsensical {
  background-color: $junGray;
}

.rounded-container {
  border-radius: $defaultBorderRadius;
  padding: $defaultBorderRadius calc(2 * $defaultBorderRadius);
}