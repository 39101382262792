@import "../../jun-styling";

.ResultSideBar.mobile {
  direction: rtl;
  overflow: visible;

  width: 0;
}

.ResultSideBar-container {
  direction: ltr;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: min-content;
  transition: max-width 0.5s ease-in-out;

  overflow-y: auto;
  scrollbar-gutter: stable;
  scrollbar-width: thin;
}

.ResultSideBar-container.collapsed {
  // This value is hardcoded to allow an animation on the max-width property.
  // The maximum width must be greater then the full width of the expand button itself (including container)
  // Otherwise the collapse animation is weird
  max-width: 50px;
  padding-left: 0;
}

.ResultSideBar-container.expanded {
  position: sticky;
  top: 1em;

  height: 100vh;
  background-color: $junHighlightBackgroundColor;
}

.ResultSideBar-container.expanded.desktop {
  max-width: 50vw;
}

.ResultSideBar-container.expanded.mobile {
  max-width: 100vw;
}

.ResultSideBar-btn {
  align-self: flex-end;
  padding-right: 0;
}

.ResultSideBar-container.desktop > .ResultSideBar-btn {
  margin-right: -12px;
}

.ResultSideBar-container > .horizontal-separator {
  margin-top: 2ex;
  margin-bottom: 2ex;
}

.StayUpdatedDialog {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1ex;
}

.StayUpdatedDialog-button {
  align-self: center;
  min-width: 70%;
}

.GoToJuriskopBot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1ex;

  margin-bottom: 2ex;
}

.GoToJuriskopBot-button {
  align-self: center;
  min-width: 70%;
}