///////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////  Arrows component  ///////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////
$arrowSize: 16px;

.Arrows {
  overflow: hidden;
}

.Arrows-arrows {
}

.Arrows-arrows.down {
  // the inner container is one arrow size larger than its outer container to allow the animation of arrows "out of" the container.
  height: calc(100% + $arrowSize);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Arrows-arrows.right {
  // the inner container is one arrow size larger than its outer container to allow the animation of arrows "out of" the container.
  width: calc(100% + $arrowSize);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Arrows-arrows > * {
  position: relative;
  font-size: $arrowSize;
}

.Arrows-arrows.down.animated > * {
  animation: Arrows-move-down 2s linear infinite;
}

.Arrows-arrows.right.animated > * {
  animation: Arrows-move-right 1.5s linear infinite;
}

@keyframes Arrows-move-down {
  0% {
    top: -$arrowSize;
  }
  100% {
    top: 0;
  }
}

@keyframes Arrows-move-right {
  0% {
    left: -$arrowSize;
  }
  100% {
    left: 0;
  }
}