.TextualDescription {
}

.TextualDescription > .ExpandableText {
  li {
    margin-bottom: 0.25lh;
  }
}

.TextualDescription-Form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.TextualDescription-character-limit {
  text-align: right;
}

.TextualDescription-submit {
  margin-top: 1.5em;
}