.SubsumeTweet {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5em;
}

.SubsumeTweet-NextStep.was-validated {
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  animation: flash .3s ease;
}

.SubsumeTweet-NextStep > .submit-button {
  width: 100%;
  margin-top: 1em;
}

.SubsumeTweet-legend {
  ul {
    margin-top: 0.5em!important;
    margin-bottom: 0.5em!important;
  }
}

.SubsumeTweet-Result {
  width: 100%;
}

@keyframes flash {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(12px);
  }
  100% {
    transform: translateX(0px);
  }
}