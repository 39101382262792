.ProfileIcon {

}

.ProfileIcon-title {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  font-size: large;
}

.ProfileMenu {
  background-color: white;
}