.SimplePagination {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.SimplePagination > .pagination-button {
  padding: 1px 10px;
}

.SimplePagination > .pagination-button.navigation-button:not(:disabled) {
  color: black!important;
}
