@import "../../../../../jun-styling";

.MultipleChoiceQuestion-question {
    align-self: flex-start;
}

.MultipleChoiceQuestion-Form {
    width: 100%;
}

.MultipleChoiceQuestion-ResponseOptions {
    align-self: flex-start;
    width: 100%;
    margin-bottom: 18px;
}

.MultipleChoiceQuestion-ResponseOptions > .form-check {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}

.MultipleChoiceQuestion-ResponseOptions > div.horizontal-separator {
    margin-top: 1ex;
    margin-bottom: 1ex;
}

.MultipleChoiceQuestion-ResponseOptions > div.horizontal-separator:last-child {
    display: none;
}

label.form-check-label {
    width: 100%;
}

.MultipleChoiceQuestion-feedback {
    color: $junLightRed;
}

.MultipleChoiceQuestion-Submit {
    width: 100%
}