@import "../../jun-styling";

.FactDescriptionVisualization-text {
  border: 2px solid $junLightGray;
  border-radius: 5px;
  padding: 1em 1em 0 1em;

  height: 100px;
  overflow-y: auto;
  resize: vertical;
}