.ResultLegend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5ex;
}

.ResultLegend-element {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.ResultLegend-color {
    min-width: 6px;
    border-radius: 3px;
}