@import "../../../jun-styling";

.InfoCard {
    display: flex;
    flex-direction: row;
    gap: 24px;
    align-items: stretch;

    border: $junUltraLightGray solid 1px;
    border-radius: var(--bs-border-radius);

    padding: 6px 12px;
}

.InfoCard-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-size: x-large;
}