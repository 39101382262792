@import "../../jun-styling";

.ViewParagraphResult-result.true > * > strong {
  color: $junGreen;
}

.ViewParagraphResult-result.false > * > strong {
  color: $junLightRed;
}

.ViewParagraphResult-content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 1em;
}

.ViewParagraphResult-colorbar {
  min-width: 6px;
  border-radius: 3px;
}

