.ViewBotMessage {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 0.5em;
}

.ViewBotMessage-content {
  margin-bottom: 25px;
}

@media (width <= 500px) {
  .ViewBotMessage-Avatar {
    display: none;
  }
}