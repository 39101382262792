@import "../../jun-styling";

.PopUp {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 2ex;

    padding: 0;

    max-width: 700px;
}

.PopUp > * {
    margin: 0 15px;
}

.PopUp > header {
    background-color: $junHighlightBackgroundColor;
    margin: 0;
    padding: 10px 0;
    text-align: center;
}

.PopUp-close-button {
    margin-bottom: 10px;
}