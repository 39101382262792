.SocialButtons {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.SocialButtons-button {
  height: 50px;
  width: 50px;
  border-radius: 10%;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.SocialButtons-icon {
  font-size: 25px;
}

.SocialButtons-LinkedIn {
  background-color: #0077b5;
  color: white;
}

.SocialButtons-Twitter {
  background-color: #1da1f2;
  color: white;
}

.SocialButtons-GitHub {
  background-color: black;
  color: white;
}