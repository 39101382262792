@import "../../../../../jun-styling";

.ChatGPTSingleResult {
  width: 100%;

  display: grid;
  grid-template-columns: 40% auto;
  grid-template-rows: auto;
  grid-template-areas:
    "name value"
    "description ."
    "show-examples ."
    "pos-examples ."
    "neg-examples .";
  column-gap: 1em;
}

@media (max-width: 500px) {
  .ChatGPTSingleResult {
    grid-template-columns: 100%;
    grid-template-areas:
      "name"
      "description"
      "show-examples"
      "pos-examples"
      "neg-examples"
      "value";
  }

  .ChatGPTSingleResult > .show-examples-button {
    margin-bottom: 12px;
  }
}

.ChatGPTSingleResult > .name {
  grid-area: name;

  margin-bottom: 6px;
  font-weight: bold;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
}

.ChatGPTSingleResult > .name.true {
  color: $junGreen;
}

.ChatGPTSingleResult > .name.unknown {
}

.ChatGPTSingleResult > .name.false {
  color: $junRed;
}


.ChatGPTSingleResult > .description {
  grid-area: description;
  margin-top: 6px;
  margin-bottom: 12px;
}

.ChatGPTSingleResult > .positive-examples {
  grid-area: pos-examples;
  margin-top: 6px;
  margin-bottom: 12px;
}

.ChatGPTSingleResult > .negative-examples {
  grid-area: neg-examples;
  margin-top: 6px;
  margin-bottom: 12px;
}

.ChatGPTSingleResult > .show-examples-button {
  grid-area: show-examples;
}

.ChatGPTSingleResult > .show-examples-button > * {
  padding: 0;
}

.ChatGPTSingleResult > .show-examples-button > button > * {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  gap: 0.5ex;

  text-align: left;
}

.ChatGPTSingleResult > .value {
  grid-area: value;

  width: 100%;
}