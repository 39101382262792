@import "../../../jun-styling";

.Message-bot {
  border: 1px $junSeparatorColor solid;
  border-radius: 12px 12px 12px 0;

  padding: 12px;
  margin-right: max(15%, 12px);

  background-color: $junHighlightBackgroundColor;
}

.Message-user {
  border: 1px $junSeparatorColor solid;
  border-radius: 12px 12px 0 12px;

  padding: 12px;
  margin-left: max(15%, 12px);

  background-color: rgba(195, 195, 195, 0.15);

}

.full-size-message {
  margin-right: 0;
}