.GeneralWorkflowDescription {
    margin: 6ex 0;
}

.GeneralWorkflowDiagram-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    margin: 6ex 0;
}