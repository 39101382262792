@import "../../jun-styling";
//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////  Progress  ///////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
.Progress {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.Progress.vertical {
  flex-direction: column;
  height: 100%;
}

.Progress > div:last-child {
  flex-grow: 0!important; // the last element has no progress bar => only use the space of the step number
}

//////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////  Progression Step  ///////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////
.ProgressionStep {
  // layout of step number and progression bar
  display: grid;
  align-items: center;
  grid-template-columns: max-content auto;
  grid-template-rows: max-content auto;
  grid-template-areas:
    "step-number progression-bar"
    "description none";

  // animation on switching steps
  flex-grow: 1;
  transition: flex-grow 1s ease;
}

.ProgressionStep.vertical {
  flex-direction: column;
}

.ProgressionStep.active {
  flex-grow: 4;
  transition: flex-grow 1s ease;
}

.ProgressionStep > .step-number {
  grid-area: step-number;

  color: $junLightGray;
  font-size: x-large;
  text-align: center;
  padding: 0 6px;

  min-width: 40px;
  min-height: 40px;
  max-height: 40px;

  white-space: nowrap;

  border-radius: 6px;
  border: solid $junLightGray 2px;

  // center the content of the step number button
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ProgressionStep > .step-number.active {
  color: white;
  border: solid $junRed 2px;
  background-color: $junRed;
}
.ProgressionStep > .step-number.completed {
  color: white;
  border: solid $junRed 2px;
  background-color: $junRed;
}

.ProgressionStep > .progression-bar {
  grid-area: progression-bar;

  background-color: $junHighlightBackgroundColor;
  width: 100%;
  height: 12px;
}
.ProgressionStep > .progression-bar.vertical {
  height: 100%;
  width: 12px;
}

.ProgressionStep > .progression-bar > .current-progression {
  background-color: $junRed;
  height: 12px;
}
.ProgressionStep > .progression-bar > .current-progression.vertical {
  background-color: $junRed;
  width: 12px;
}

.ProgressionStep > .progression-bar > .current-progression.active {
  transition: width 300ms linear;
}


.ProgressionStep > .description {
  grid-area: description;
  // We set the width of the enclosing div to 0, to avoid that the grid cell is resized to fit the whole description.
  // With this trick, the child div (containing the actual description) can overflow outside the grid cells without
  // enlarging the step number buttons.
  width: 0;
  // to center the description underneath the step number
  place-self: center;

  font-weight: bold;
}
// special cases of first and last progression bar => do not center, otherwise they will overflow to left/right.
.ProgressionStep:first-child > .description {
  justify-self: start;
}
.ProgressionStep:last-child > .description {
  justify-self: end;
}

.ProgressionStep > .description > * {
  width: max-content;
  transform: translateX(-50%);
}


// special cases of first and last progression bar => do not center, otherwise they will overflow to left/right.
.ProgressionStep:first-child > .description > div {
  transform: translateX(0);
}
.ProgressionStep:last-child > .description > div {
  transform: translateX(-100%);
}