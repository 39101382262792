.MarkQualityInput {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

div.MarkQualityInput-no {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}
