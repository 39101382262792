@import "../../jun-styling";

.IntersectionalBot {
    border: $junSeparatorColor solid 1px;
    border-radius: 12px;

    padding: 12px;

    display: flex;
    flex-direction: column;
    gap: 4ex;
}
