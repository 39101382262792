@use 'sass:color';
@import "../../../jun-styling";


///////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////  TwitterPipeline component  //////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////

.TwitterPipeline > .pipeline {
  display: grid;
  grid-template-columns: [col-start] repeat(5, auto) [col-end];
  grid-template-rows: [row-start] auto auto [row-end];
  grid-template-areas:
          "tweet-ChatGPT           .           .             .                    ."
          "ChatGPT          ChatGPT-logic    logic      logic-result         result"
          "ChatGPT-label     logic-label   logic-label   logic-label   result-label";
  align-items: center;
  justify-items: center;
  justify-content: center;

  column-gap: 1em;
  margin-top: 1em;

  ////////////////// Tweet -> ChatGPT /////////////////////////////
  .tweet-to-roboter {
    grid-area: tweet-ChatGPT;
    margin-top: 8px;
  }

  ////////////////////////////////////////////////////////////////

  ///////////////////// ChatGPT /////////////////////////////////
  .ChatGPT {
    grid-area: ChatGPT;

    .roboter > .roboter-icon {
      font-size: 100px !important;
    }

    .roboter > .gear {
      font-size: 33px;
    }
  }

  ////////////////////////////////////////////////////////////////
  ///////////////////// ChatGPT-label /////////////////////////////
  .ChatGPT-label {
    grid-area: ChatGPT-label;
  }

  ////////////////////////////////////////////////////////////////

  ////////////////// ChatGPT -> Logic ////////////////////////////
  .ChatGPT-to-logic {
    grid-area: ChatGPT-logic;
  }

  ////////////////////////////////////////////////////////////////

  ///////////////////// Logic ///////////////////////////////////
  .Logic {
    grid-area: logic;

    font-size: 80px;
  }

  ////////////////////////////////////////////////////////////////
  ///////////////////// Logic-label /////////////////////////////
  .Logic-label {
    grid-area: logic-label;
  }

  ////////////////////////////////////////////////////////////////

  ////////////////// Logic -> Result ////////////////////////////
  .logic-to-result {
    grid-area: logic-result;
  }

  ////////////////////////////////////////////////////////////////

  ///////////////////// Result ///////////////////////////////////
  .Result {
    grid-area: result;

    font-size: 60px;
  }

  ////////////////////////////////////////////////////////////////
  ///////////////////// Result-label /////////////////////////////
  .Result-label {
    grid-area: result-label;
  }

  ////////////////////////////////////////////////////////////////


  .hidden {
    color: color.change($junUltraLightGray, $alpha: 0.4);
  }

  .label {
    text-align: center;
  }
}


@media (max-width: 800px) {
  .TwitterPipeline > .pipeline {
    column-gap: 0.2em;

    .ChatGPT > .roboter > .roboter-icon {
      font-size: 50px !important;
    }

    .ChatGPT > .roboter > .gear {
      font-size: 20px !important;
    }

    .Logic {
      font-size: 40px;
    }

    .Logic-label {
      max-width: 70%;
      overflow-wrap: break-word;
      hyphens: auto;
    }

    .Result {
      font-size: 30px;
    }
  }
}