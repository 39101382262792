@import "../../jun-styling";

.Header {
  display: grid;
  grid-template-columns: auto max-content;
  grid-template-rows: max-content max-content max-content;
  grid-template-areas:
    ". profile"
    "title title"
    "subtitle subtitle";

  // alignment
  align-items: center;
  column-gap: 2ex;

  text-align: center;

  width: 100%;

  padding: 0 12px;

  // background image
  min-height: 250px;
  background: white url("./Juriskop-header-background-image.png") no-repeat bottom -70px right 50%;
  background-size: max(100vw, 1920px) 100%; // stretch only on large screens
}

@media (max-width: 600px) {
  .Header {
    text-align: left;

    // background image
    min-height: 225px;
    background: white url("./Juriskop-header-background-image.png") no-repeat bottom -60px right 50%;
    background-size: 1200px 100%;
  }

  .Header-profile {
    margin-left: auto;
  }

  .Header-title {
    margin-top: 0!important;
  }
}

.Header-title {
  font-size: 42px;
  grid-area: title;

  margin-top: 0;
}

.Header-subtitle {
  font-size: 34px;
  grid-area: subtitle;
  color: $junRed;

  overflow-wrap: anywhere;
  hyphens: auto;
}

.Header-profile {
  grid-area: profile;
  justify-self: end;
}