.ViewPropertyArray {
  margin-bottom: 48px;
}

.ViewPropertyArray > .body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ViewPropertyArray-Item {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.ViewPropertyArray-Item > .properties {
  width: 100%;
  margin: 0 0;
}

.navigation-button:not(:disabled) {
  color: black!important;
}

.bottom-indent-separator {
  align-self: flex-start;
}
