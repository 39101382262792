@import "../../../../../jun-styling";

.properties {
  width: 100%;
}

.ViewPropertyGroup {
  margin-bottom: 48px;
}

.ViewPropertyGroup-SingleProperty {
  margin-bottom: 12px;
  width: 100%;
}

.ViewPropertyGroup-SingleProperty  > .property {
  margin: 12px 0;
}

.ViewPropertyGroup-SingleProperty > .horizontal-separator {
  width: 100%;
}

