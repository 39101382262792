.IconList {
  list-style: none;
  padding-left: 0;
}

.IconList > li:not(:last-child) {
  margin-bottom: .5lh;
}

.IconList > li {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5em;
}

.IconList-bullet {
}