@import "../../../jun-styling";

.context-tweet-container {
  padding-bottom: 1em;
  padding-left: 1em;
  margin-left: 1em;
  border-left: solid 2px black;

  display: flex;
  flex-direction: column;
  gap: 1em;
}

.main-tweet {
  border-color: black!important;
  border-width: 2px!important;
}