.Footer {
  margin: 24px 6px 12px 6px;

  display: grid;
  grid-template-columns: 38% 38%;
  grid-template-rows: auto;
  grid-template-areas:
    "address cooperation"
    "compliance empty"
    "socials socials"
    "version version";
  justify-content: center;
  row-gap: 24px;
}

@media (max-width: 500px) {
  .Footer {
    display: grid;
    grid-template-columns: 76%;
    grid-template-rows: auto;
    grid-template-areas:
    "address"
    "compliance"
    "cooperation"
    "socials"
    "version";
  }
}

.Footer-address {
  grid-area: address;
}

.Footer-cooperation {
  grid-area: cooperation;
}

.Footer-compliance {
  grid-area: compliance;
}

.Footer-socials {
  margin: auto;
  grid-area: socials;
}

.Footer-version {
  text-align: right;
  grid-area: version;
}
@media (max-width: 500px) {
  .Footer-version {
    text-align: center;
  }
}