.ProcessingTimeIndicator {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Hourglass {
  margin-right: 1em;
}

.Hourglass.rotate {
  animation: Hourglass-rotate-back .5s ease forwards;
}

@keyframes Hourglass-rotate-back {
  0% {
    rotate: 0;
  }
  100% {
    rotate: 180deg;
  }
}