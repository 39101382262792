.BotIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BotIcon > .roboter {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}


.BotIcon > .roboter > .gear {
  position: relative;
  width: 0;
  height: auto;
  transform: translateY(-30%);
}

.BotIcon > .label {
  }