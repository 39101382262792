.GeneralWorkflowDiagram {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.single-step {
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-areas: "icon"
        "description";
    justify-items: center;
    align-content: center;

    row-gap: 0.5em;

    /* draw circle */
    border: 1px;
    border-radius: 100%;

    height: 170px;
    width: 170px;
}

.single-step > .icon {
    font-size: 34px;

    grid-area: icon;
    align-self: end;
}

.single-step > .description {
    text-align: center;

    grid-area: description;
    align-self: start;
}

.input.single-step {
    border-style: solid;
    border-color: #d5d5d5;
}

.analyze.single-step {
    background-color: #d5d5d5;
}

.results.single-step {
    background-color: #a3a3a3;
}


/*****************************************************************************
 **************************** Mobile style overwrites ************************
 *****************************************************************************/
@media (max-width: 800px) {
    .GeneralWorkflowDiagram {
        display: grid;
        grid-template-areas: "input-icon input-description"
            "arrow-input-analyze ."
            "analyze-icon analyze-description"
            "arrow-analyze-result ."
            "result-icon result-description";

        grid-column-gap: 2em;
    }

    .input.icon {
        grid-area: input-icon;
    }
    .input.description {
        grid-area: input-description;
    }

    .arrow.input-to-analyze {
        grid-area: arrow-input-analyze;
    }

    .analyze.icon {
        grid-area: analyze-icon;
    }
    .analyze.description {
        grid-area: analyze-description;
    }

    .arrow.analyze-to-result {
        grid-area: arrow-analyze-result;
    }

    .result.icon {
        grid-area: result-icon;
    }
    .result.description {
        grid-area: result-description;
    }

    .arrow {
        justify-self: center;
    }
    .icon {
        justify-self: center;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 120px;
        height: 120px;

        border: 1px;
        border-radius: 100%;

        font-size: 36px;
    }

    .input.icon {
        border-style: solid;
        border-color: #d5d5d5;
    }

    .analyze.icon {
        background-color: #d5d5d5;
    }

    .result.icon {
        background-color: #a3a3a3;
    }

    .single-step > .description {
        font-weight: bold;
        font-size: large;
    }
}
