.ChatGPTResult {
  width: 100%;
  margin: 1em auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 14px;
}

.ChatGPTResult > .toggle-advanced-view {
  align-self: flex-start;
}

@media (max-width: 500px) {
  .ChatGPTResult {
    width: 100%;
  }
  .ChatGPTResult > .toggle-advanced-view {
    align-self: stretch;
  }
}