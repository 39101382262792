.ExpandableText {
    display: grid;
    grid-template-columns: max-content auto;
    grid-template-rows: max-content auto;
    grid-template-areas: "button title"
        ". content";

    grid-gap: 0.75em;
}

.ExpandableText-title-button {
    grid-area: button;
}
.ExpandableText-title {
    grid-area: title;
}

.ExpandableText-content {
    grid-area: content;
}

.ExpandableText-content.showing {
}

.ExpandableText-content.hidden {
    aria-hidden: true;
    visibility: hidden;
    height: 0;
    overflow: clip;
}